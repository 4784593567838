<template>
    <section v-show="id_pedido">
        <div v-if="pedido.id_pedido" class="border-bottom">
            <div class="row mx-0 my-3 align-items-center">
                <div class="col-2">
                    <i class="icon-account text-general f-20 border rounded-circle p-2 bg-whitesmoke" />
                </div>
                <div class="col">
                    <p class="col f-13 text-general">Pedido del cliente</p>
                    <p class="col f-13 text-general">Administrado por el CEDIS</p>
                </div>
            </div>
        </div>
        <div v-if="pedido.id_pedido == null" class="border-bottom">
            <div class="row mx-auto text-general f-14 justify-center my-2">
                <span class="f-500">Datos de mi cliente</span>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-cliente text-general f-20 mr-2" />
                <p class="col f-13 text-general">{{ pedido.digitado_cliente_nombre || 'No registra' }}</p>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-phone text-general f-20 mr-2" />
                <p class="col f-13 text-general">{{ pedido.digitado_cliente_telefono || 'No registra' }}</p>
            </div>
        </div>
        <div v-if="pedido.id_pedido && pedido.cliente_entrega_directa" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div style="width:41px;" />
                <p class="col f-500 f-17 pl-0">Cliente</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <img class="border rounded-circle bg-whitesmoke" :src="pedido.datos_pedido.avatar" width="34" height="34" alt="" />
                <p class="mx-2 f-15">{{ pedido.datos_pedido.nombre }}</p>
            </div>
            <div class="row mx-0 my-2">
                <div style="width:41px;">
                    <i class="icon-calendar text-general f-20" />
                </div>
                <div class="col pl-0 f-15">
                    <p class="text-general f-500 f-16">Fecha de entrega</p>
                    <p v-if="pedido.uso_horario === 1" class="text-general f-15 mt-2">{{ pedido.fecha_entrega | helper-fecha('DD MMM YYYY hh:mm') }}</p>
                    <p v-else class="mt-2 f-15">No programado</p>
                </div>
            </div>
            <div class="row mx-0 my-2">
                <div style="width:41px;">
                    <i class="icon-location f-20" />
                </div>
                <div class="col pl-0 f-15">
                    <p class="text-general f-500 f-16">Dirección de entrega</p>
                    <p class="text-general f-15 mt-2" v-html="formatearTextoHtml(pedido.datos_pedido.direccion)" />
                    <p class="mt-2 f-15">{{ pedido.distancia }} Mts Aprox.</p>
                </div>
            </div>
            <div class="row mx-0 mt-2 f-15">
                <div style="width:41px;">
                    <i class="icon-money f-18 text-general" />
                </div>
                <div class="col pl-0">
                    <b>Forma de Pago:</b>
                    {{ funFormaPago(pedido.metodo_pago) }}
                </div>
                <div v-if="pedido.pago_teorico == 4" class="px-5">
                    <p class="f-13"> <b class="f-15">Estado:</b> {{ pagoOnline.estado == 4 ? 'Aprobado' : 'En espera' }}</p>
                    <p class="f-13"> No. {{ pagoOnline.referencia }}</p>
                </div>
            </div>
            <div class="row mx-0 mt-2 f-15">
                <div style="width:41px;">
                    <i class="icon-shopping f-18 text-general" />
                </div>
                <div class="col pl-0">
                    <b>Entrega:</b>
                    {{ pedido.cliente_entrega_directa == 1 ? 'Leeche' : $config.vendedor }}
                </div>
            </div>
        </div>
        <div v-if="pedido.id_pedido == null" class="border-bottom text-general">
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-location text-general f-20 mr-2" />
                <p class="col f-13" v-html="formatearTextoHtml(cliente.direccion)" />
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-arrow-top-right text-general f-18 mr-2" />
                <p class="col f-13">{{ pedido.distancia }} Mts Aprox.</p>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-calendar-clock text-general f-22 mr-2" />
                <p v-if="pedido.uso_horario === 1" class="col f-13"><b>Fecha de entrega:</b> {{ pedido.fecha_entrega | helper-fecha('DD MMM YYYY') }}</p>
                <p v-else class="col f-13"><b>Fecha de entrega:</b> No programado</p>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-truck-fast-outline text-general f-20 mr-2" />
                <p class="col f-13"> Valor Domicilio: {{ convertMoneyTendero(pedido.valor_envio,pedido.idm_moneda) }}</p>
            </div>
            <div class="row mx-0 my-2 px-2 align-items-center">
                <i class="icon-money f-20 mr-2" />
                <p class="px-3 f-13"> <b>Forma de pago:</b> {{ funFormaPago(pedido.metodo_pago) }}</p>
                <div v-if="pedido.pago_teorico == 4" class="px-5">
                    <p class="f-13"> <b class="f-15">Estado:</b> {{ pagoOnline.estado == 4 ? 'Aprobado' : 'En espera' }}</p>
                    <p class="f-13"> No. {{ pagoOnline.referencia }}</p>
                </div>
            </div>
        </div>
        <div class="border-bottom p-2">
            <div class="row mx-0 align-items-center">
                <div style="width:41px;">
                    <i class="icon-text-box-check-outline f-20 text-general" />
                </div>
                <p class="col pl-0 f-500 f-15 text-general">Alianza {{ $config.vendedor }}</p>
            </div>
            <div class="row mx-0 mb-2 text-general align-items-center">
                <div style="width:41px;" />
                <div class="col pl-0">
                    <p class="f-13">Cambia tu condición</p>
                    <el-select
                    v-model="pedido.id_condicion"
                    size="medium"
                    :disabled="pedido.id_pedido != null || $tienda.cambio_condiciones == 0"
                    class="w-100"
                    placeholder="Seleccione condición"
                    @change="setCondicion"
                    >
                        <el-option
                        v-for="item in condiciones"
                        :key="item.id"
                        :label="item.condicion"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <!-- linea de tiempo -->
        <div class="border-bottom py-2">
            <div class="row mx-0 justify-center">
                <div v-for="(act, index) in historial" :key="index" class="d-middle" style="width:55px;">
                    <div class="rounded-circle position-relative" :class="fun_bg(act.activo, index)" style="width:40px;height:40px;padding:1px">
                        <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                            <i :class="`history-line f-20 ${act.icon} ${fun_text(act.activo, index)}`" />
                        </div>
                    </div>
                    <div v-show="index != historial.length-1" class="w-25" :class="fun_bg( act.activo, index)" style="height:2px;" />
                </div>
            </div>
            <p class="my-2 text-general f-500 text-center my-3">
                {{ nombreEstado }}
            </p>
            <template v-if="showHistorial">
                <div v-for="(act, index) in historial" :key="index" class="row mx-0">
                    <div class="col-auto">
                        <div class="rounded-circle position-relative" :class="fun_bg(act.activo, index)" style="width:20px;height:20px;padding:1px">
                            <div class="w-100 h-100 rounded-circle d-middle-center bg-white">
                                <i :class="`f-20 icon-ok-circled ${fun_text(act.activo, index)}`" />
                            </div>
                        </div>
                        <div v-show="index != historial.length-1" class="mx-auto h-100 py-2" :class="fun_bg( act.activo, index)" style="width:2px;" />
                    </div>
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col px-1">
                                <p :class="fun_text(act.activo, index)" class="f-14">{{ act.content }}</p>
                                <p class="f-13">{{ act.hora | helper-fecha('hh:mm a DD MMM') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <p class="my-2 text-general f-500 text-center cr-pointer" @click="showHistorial = !showHistorial">
                <span v-text="showHistorial ? 'Ver menos' : 'Ver más'" />
                <i :class="`${showHistorial ? 'icon-angle-up' : 'icon-angle-down'}`" />
            </p>
        </div>
        <div class="border-bottom p-2">
            <div class="row mx-0 cr-pointer">
                <div class="col-auto pr-2 pl-0">
                    <i class="icon-pedidos f-20 text-gr-general" />
                </div>
                <div class="col pl-0 my-auto text-general f-17 f-500">
                    Más información
                </div>
            </div>
            <div class="row mx-0 mb-2">
                <div class="col-auto px-3" />
                <div class="col pl-0">
                    <div class="row mx-0">
                        <div class="col pl-1 f-16 text-general">
                            <p class="text-general f-500 mt-3">Instrucciones</p>
                            <p class="text-general f-14">{{ pedido.instrucciones || 'No registra instrucciones' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-2">
            <div class="row mx-0 px-2 text-general f-600 f-18">
                Detalle del cobro
            </div>
            <div class="row mx-0 px-2 justify-content-between text-general">
                <p>Costo de productos ({{ resumen.cantidad_productos }})</p>
                <b>{{ convertMoneyTendero(resumen.val_productos,pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 justify-content-between text-general">
                <p>Descuento</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumen.val_descuentos,pedido.idm_moneda) }}
                </b>
            </div>
            <div v-if="resumen.id_cupon" class="row mx-0 px-2 justify-content-between">
                <div class="d-middle">
                    <i class="icon-cupon pr-1" />
                    <p>{{ resumen.codigo_cupon }}</p>
                </div>
                <p class="text-general-red f-500">
                    {{ convertMoneyTendero(resumen.val_cupon, pedido.idm_moneda) }}
                </p>
            </div>
            <div v-show="resumen.val_condicion > 0" class="row mx-0 px-2 justify-content-between">
                <p class="text-general">Alianza {{ $config.vendedor }}</p>
                <b class="text-general-red">
                    {{ convertMoneyTendero(resumen.val_condicion,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 f-600 justify-content-between text-general border-top pt-2">
                <p>Sub Total</p>
                <p>{{ convertMoneyTendero(resumen.subtotal,pedido.idm_moneda) }}</p>
            </div>
            <div class="row mx-0 px-2 justify-content-between text-general">
                <p>Costo de impuestos</p>
                <b class="text-general">
                    {{ convertMoneyTendero(resumen.val_impuestos,pedido.idm_moneda) }}
                </b>
            </div>
            <div class="row mx-0 px-2 justify-content-between text-general">
                <p>Costo de envío</p>
                <b>{{ convertMoneyTendero(resumen.val_domicilios,pedido.idm_moneda) }}</b>
            </div>
            <div class="row mx-0 px-2 mt-2 f-600 justify-content-between text-general border-top pt-2">
                <p>Total a cobrar</p>
                <p>{{ convertMoneyTendero(resumen.val_total,pedido.idm_moneda) }}</p>
            </div>
        </div>
        <!-- Inicio convenio nómina -->
        <div v-if="convenio != null" class="border-bottom text-general p-2">
            <div class="row mx-0 my-2 align-items-center">
                <div class="col-auto pl-0 pr-2">
                    <i class="icon-convenio f-22" />
                </div>
                <p class="col f-600 f-17 pl-0 text-general">Convenio de nómina</p>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <img class="border br-10 bg-whitesmoke obj-cover" :src="convenio.convenio_logo != null ? convenio.convenio_logo : '/img/no-imagen/no-imagen-empresa.svg'" width="45" height="45" alt="" />
                <div class="col">
                    <p class="f-15 f-500 text-general">{{ convenio.convenio }}</p>
                    <p class="f-15" :style="convenio.icon === 'bien' ? 'color:#29D884' : 'color:#FF5806;'">
                        <i v-if="convenio.icon === 'bien'" class="icon-ok-circled-outline f-18" style="color:#29D884;" />
                        <i v-else class="icon-attention-alt f-18" style="color:#FF5806;" />
                        {{ convenio.convenio_estado }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 align-items-center my-2">
                <div class="col-auto px-3 mr-1" />
                <div class="col">
                    <p class="f-15  text-general">
                        {{ convenio.convenio_efectivo ? 'Cobrar al entrega el pedido' : 'Cobrar en descuento de nómina' }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Fin convenio nómina -->
        <modalVerInstrucciones ref="modalVerInstrucciones" />
        <modalPagarEntrega ref="modalPagarEntrega" />
    </section>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    components: {
        modalVerInstrucciones: () => import('../../pedidos/partials/modalVerInstrucciones'),
        modalPagarEntrega: () => import('../partials/modalPagarEntrega'),
    },
    data(){
        return{
            condiciones:[],
            showHistorial: false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',
            pedido: 'pedidos/pedidos_historial_tendero/pedido',
            cliente: 'pedidos/pedidos_historial_tendero/cliente',
            historial: 'pedidos/pedidos_historial_tendero/historial',
            resumen: 'pedidos/pedidos_historial_tendero/resumen',
            entrega: 'pedidos/pedidos_historial_tendero/entrega',
            convenio: 'pedidos/pedidos_historial_tendero/convenio',
            pagoOnline: 'pedidos/pedidos_historial_tendero/pagoOnline',

        }),
        nombreEstado(){
            if(!this.historial.length) return ''
            let filtered = this.historial.filter(el => el.activo)
            if(!filtered.length) return ''

            return filtered[filtered.length - 1]["content"] ?? '';
        }
    },
    watch:{
        id_pedido(value){
            if(value){
                this.listarCondiciones()
            }
        }
    },
    methods: {
        async listarCondiciones(){
            if(this.condiciones.length !== 0) return
            try {
                const {data} = await Pedidos.get_condiciones(this.id_pedido)
                this.condiciones = data.condiciones
            } catch (e){
                this.error_catch(e)
            }
        },
        verInstrucciones(){
            this.$refs.modalVerInstrucciones.toggle(this.pedido)
        },
        color_fondo(cant){
            if(cant === 0){
                return '#FF0000'
            }else if(cant > 0 && cant <= 5){
                return '#FCA105'
            }
            else if(cant > 5 && cant <= 20){
                return '#00C93C'
            }
            else if(cant > 20 && cant <= 100){
                return '#8701AE'
            }
            else if(cant > 100){
                return '#0028C9'
            }
        },
        fun_bg(activo,idx){
            switch (idx){
            case 6:
                return 'bg-danger'
            default:
                if(activo) return 'bg-general3'
                else return 'bg-gris'

            }
        },
        fun_text(activo,idx){
            switch (idx){
            case 6:
                return 'text-danger'
            default:
                if(activo) return 'text-gr-general'
                else return 'text-gris'

            }
        },
        async setCondicion(value){
            try {
                const {data} = await Pedidos.set_condicion(this.id_pedido,value)
                this.notificacion('Mensaje','Se ha actualizado la condición de la alianza','success')
                this.$store.dispatch('pedidos/pedidos_historial_tendero/pedidos_resumenes', this.id_pedido)
            } catch (e){
                this.error_catch(e)
            }
        },
        funFormaPago(metodo){
            switch (this.pedido.pago_teorico){
            case 1:
                return 'Pago en Efectivo'
            case 2:
                return `Pago por ${metodo}`
            case 3:
                return 'Pedido a Credito'
            case 4:
                return 'Pagué en Linea'
            case 5:
                return 'Descontar de Nomina Cliente'
            default:
                return 'Pago en Efectivo'
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.br-t-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.ticket {
  border: 1px solid #28d07b;
  background-color: #28d07b33;
  .total-value {
    color: #28d07b;
    font-weight: 600;
  }
}
.history-line::before{
    display: contents !important;
}
</style>
